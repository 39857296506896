
//http://localhost:8080/#/claim/EnquiryProgram
import {
    defineComponent,
    ref,
    reactive,
    toRefs,
    nextTick,
    computed,
} from 'vue';
import { getQueryList, deleteInfo } from '@/API/claim/claimQuery';
import { getVlList } from '@/API/salesReporting/VehicleLicenseTrackingReport';
import {
    getDealerList,
    getProgramList,
} from '@/API/salesReporting/claimCheckingReport';
import { message, TreeSelect } from 'ant-design-vue';
import { Modal } from 'ant-design-vue';
// import setColumnWidth from "@/utils/claim/setColumnWidth"
import downloadFile from '@/utils/claim/downloadFile';
import moment from 'moment';
import { useStore } from 'vuex';
import useFetch from '@/hooks/useFetch';
import { getUser } from '@/API/claim/delaerClaim/delaer';
import DM from '@/views/SalesReporting/components/downloadModal.vue';
// import axios from "axios";
const SHOW_PARENT = TreeSelect.SHOW_PARENT;
const tableWidth = window.innerWidth;
const tableWidth2 = (window.innerWidth * 4) / 9;
const tableWidth3 = (window.innerWidth * 5) / 7;
const sunCol1 = reactive([
    { title: 'Region', dataIndex: 'region', width: 100 },
    {
        title: 'Vehicle License Eligible Submission Rate %',
        dataIndex: 'rate',
        width: 200,
    },
    { title: 'Eligible Retail No.', dataIndex: 'subCount', width: 200 },
    { title: 'Eligible Vehicle License No.', dataIndex: 'rstCount' },
    // { title: "Operation", dataIndex: "checkResult", width: 200, slots: { customRender: "operation" } },
]);
const sunCol2 = reactive([
    { title: 'Region', dataIndex: 'region', width: 100 },
    {
        title: 'Vehicle License Eligible Submission Rate>=X% Dealer Rate %',
        dataIndex: 'rate',
        width: 250,
    },
    { title: 'Eligible Dealer No.', dataIndex: 'allCount', width: 200 },
    {
        title: 'Vehicle License Eligible Submission Rate>=X% Dealer No.',
        dataIndex: 'moreCount',
        width: 250,
    },
    {
        title: 'Vehicle License Eligible Submission Rate<X% Dealer No.',
        dataIndex: 'lessCount',
    },
]);
const sunCol3 = reactive([
    { title: 'SubRegion', dataIndex: 'region', width: 100 },
    {
        title: 'Vehicle License Eligible Submission Rate %',
        dataIndex: 'rate',
        width: 200,
    },
    { title: 'Eligible Retail No.', dataIndex: 'subCount', width: 200 },
    { title: 'Eligible Vehicle License No.', dataIndex: 'rstCount' },
]);
const sunCol4 = reactive([
    { title: 'SubRegion', dataIndex: 'region', width: 100 },
    {
        title: 'Vehicle License Eligible Submission Rate>=X% Dealer Rate %',
        dataIndex: 'rate',
        width: 250,
    },
    { title: 'Eligible Dealer No.', dataIndex: 'allCount', width: 200 },
    {
        title: 'Vehicle License Eligible Submission Rate>=X% Dealer No.',
        dataIndex: 'moreCount',
        width: 250,
    },
    {
        title: 'Vehicle License Eligible Submission Rate<X% Dealer No.',
        dataIndex: 'lessCount',
    },
    // { title: "Operation", dataIndex: "checkResult", width: 200, slots: { customRender: "operation" } },
]);
const rawDataCol = reactive([
    { title: 'Dealer Code (Cofico)', dataIndex: 'dealerCode', width: 200 },
    { title: 'Dealer Code (SWT)', dataIndex: 'dealerCodeSwt', width: 200 },
    { title: 'Dealer Name', dataIndex: 'dealerName', width: 200 },
    { title: 'POCC date', dataIndex: 'poccDate', width: 200 },
    { title: 'Dealer Group', dataIndex: 'dealerGroup', width: 200 },
    {
        title: 'Region',
        dataIndex: 'region',
        width: 200,
    },
    { title: 'Region_CN', dataIndex: 'regionCn', width: 200 },
    { title: 'SubRegion', dataIndex: 'subRegionEn', width: 200 },
    { title: 'Province', dataIndex: 'provinceCn', width: 200 },
    { title: 'City', dataIndex: 'cityCn', width: 200 },
    { title: 'Year', dataIndex: 'year', width: 200 },
    { title: 'Month', dataIndex: 'month', width: 200 },
    {
        title: 'Vehicle License Eligible Submission Rate %',
        dataIndex: 'rate',
        width: 180,
    },
    { title: 'Eligible Retail No.', dataIndex: 'subCount', width: 200 },
    {
        title: 'Eligible Vehicle License No.',
        dataIndex: 'rstCount',
        width: 200,
    },
    {
        title: 'Ineligible Vehicle License No.',
        dataIndex: 'unPassCount',
        width: 160,
    },
    {
        title: 'Final Result_If Eligible Submission',
        dataIndex: 'ifUseful',
        width: 200,
    },
    {
        title: 'Special Notes（POCC exemption for X month）',
        dataIndex: 'poccFlag',
        width: 220,
    },
]);
interface TreeDataItem {
    value: string;
    key: string;
    title?: string;
    disabled?: boolean;
    children?: TreeDataItem[];
}
const qmOptions: TreeDataItem[] = [
    {
        title: 'Q1',
        value: 'Q1',
        key: 'Q1',
        children: [
            {
                title: '01',
                value: '01',
                key: '01',
            },
            {
                title: '02',
                value: '02',
                key: '02',
            },
            {
                title: '03',
                value: '03',
                key: '03',
            },
        ],
    },
    {
        title: 'Q2',
        value: 'Q2',
        key: 'Q2',
        children: [
            {
                title: '04',
                value: '04',
                key: '04',
            },
            {
                title: '05',
                value: '05',
                key: '05',
            },
            {
                title: '06',
                value: '06',
                key: '06',
            },
        ],
    },
    {
        title: 'Q3',
        value: 'Q3',
        key: 'Q3',
        children: [
            {
                title: '07',
                value: '07',
                key: '07',
            },
            {
                title: '08',
                value: '08',
                key: '08',
            },
            {
                title: '09',
                value: '09',
                key: '09',
            },
        ],
    },
    {
        title: 'Q4',
        value: 'Q4',
        key: 'Q4',
        children: [
            {
                title: '10',
                value: '10',
                key: '10',
            },
            {
                title: '11',
                value: '11',
                key: '11',
            },
            {
                title: '12',
                value: '12',
                key: '12',
            },
        ],
    },
];
interface DealerType {
    dealerCode: string;
    dealerNameCn: string;
    programCode: string;
    eligiblePeriodStr: string;
    offerTypeName: string;
    optionName: string;
    vinNo: string;
    model: string;
    inParagraph: string;
    payRound: string;
    checkResult: string;
    complaintStatus: string;
    predictAmount: string;
    price: string;
    createdTime: string;
    status: string;
}
interface ProgramType {
    dealerCode: string;
    dealerNameCn: string;
    programCode: string;
    eligiblePeriodStr: string;
    offerTypeName: string;
    optionName: string;
    vinNo: string;
    model: string;
    inParagraph: string;
    payRound: string;
    checkResult: string;
    complaintStatus: string;
    predictAmount: string;
    price: string;
    createdTime: string;
    status: string;
}
interface TypeClassItem {
    typeClass: string;
}
export default defineComponent({
    components: {
        DM,
    },
    setup() {
        const { state } = useStore();
        const searchCon = ref();
        const tableHeight = ref();
        const tableHeight2 = ref();
        const tableBoxHeight = ref();
        const endPeriod = ref<any>(new Date().getMonth() + 1);
        const inputPersent = ref<number>(95);
        //显示的表格数据
        const parameterList = ref<any>({
            bu: null,
            period: `${new Date().getFullYear()}-${new Date().getMonth() + 1}`,
        });
        let buCopy = '';
        //获取bu选项
        const buList = computed(() =>
            state.user.buList
                .filter((bu: any) => {
                    return bu.parentId === null;
                })
                .filter((bu: any) => {
                    return bu.nameEn !== 'Companycar' && bu.nameEn !== 'CPO';
                })
        );
        const doGetBuList = () => {
            if (buList.value.length === 0) return;
            const mbIndex = buList.value.findIndex((i: any) => {
                return i.nameEn === 'MBPC';
            });
            const initIndex = mbIndex === -1 ? 0 : mbIndex;
            const value = buList.value[initIndex].nameEn;
            parameterList.value.bu = value;
            buCopy = value;
        };

        //重置
        const handlerReset = () => {
            parameterList.value.bu = buCopy;
            parameterList.value.period = `${new Date().getFullYear()}-${new Date().getMonth() +
                1}`;
            doGetBuList();
        };

        //整理获取parameter
        const parameterDataLst = ref<any>({
            month: [],
            bu: '',
            buId: '',
            xrate: '',
            year: '',
            flag: '',
        });
        const dogetParameter = (flag: any) => {
            parameterDataLst.value.bu = parameterList.value.bu;
            buList.value.forEach((bui: any, buind: any) => {
                if (bui.nameEn === parameterList.value.bu) {
                    parameterDataLst.value.buId = bui.id;
                }
            });
            if (parameterList.value.period) {
                parameterDataLst.value.year = parameterList.value.period.split(
                    '-'
                )[0];
            }
            const monthArr = [];
            if (
                endPeriod.value &&
                parseInt(endPeriod.value) &&
                parseInt(parameterList.value.period.split('-')[0])
            ) {
                if (
                    !(
                        parseInt(parameterList.value.period.split('-')[1]) >
                        parseInt(endPeriod.value)
                    )
                ) {
                    for (
                        let i = parseInt(
                            parameterList.value.period.split('-')[1]
                        );
                        i < parseInt(endPeriod.value) + 1;
                        i++
                    ) {
                        const monthitem = i + '';
                        if (monthitem.length > 1) {
                            monthArr.push(monthitem);
                        } else {
                            monthArr.push('0' + monthitem);
                        }
                    }
                }
            }
            parameterDataLst.value.month = monthArr;
            parameterDataLst.value.xrate = inputPersent.value;
            parameterDataLst.value.flag = flag;
        };
        nextTick(() => {
            (document.getElementsByClassName(
                'table-box'
            )[0] as any).style.height =
                window.innerHeight -
                180 -
                searchCon.value.$el.scrollHeight +
                'px';
            const height = (document.getElementsByClassName(
                'table-box'
            )[0] as any).style.height;
            const heightNum = parseInt(height.substr(0, height.indexOf('px')));
            tableHeight.value = heightNum - 150;
            tableHeight2.value = heightNum - 190;
            tableBoxHeight.value = heightNum - 50;
        });
        const dealerByRegionData = ref<any[]>([]);
        const dealerBySubRegionData = ref<any[]>([]);
        const smtByRegionData = ref<any[]>([]);
        const smtBySubRegionData = ref<any[]>([]);
        const rowdataData = ref<any[]>([]);
        const doGetDealerData = () => {
            if (!parameterList.value.period) {
                message.error('Retail period (Year) must be selected!');
                return;
            }
            dogetParameter('');
            if (parameterDataLst.value.month < 1) {
                message.error('结束时间不可大于开始时间！');
            } else {
                getVlList(parameterDataLst.value).then((res) => {
                    dealerByRegionData.value = res.dealerByRegion;
                    dealerBySubRegionData.value = res.dealerBySubRegion;
                    smtByRegionData.value = res.smtByRegion;
                    smtBySubRegionData.value = res.smtBySubRegion;
                    rowdataData.value = res.rowdata;
                });
            }
        };
        const dogetAllData = () => {
            doGetDealerData();
        };
        // dogetAllData()
        const initPage = () => {
            doGetBuList();
            window.setTimeout(() => {
                dogetAllData();
            }, 30);
        };
        initPage();
        //切换tab
        const tabN = ref<any>('Summary View');
        const tabChange = (e: any) => {
            if (e === '1') {
                tabN.value = 'Summary View';
            } else if (e === '2') {
                tabN.value = 'Raw Data';
            }
        };

        const downloadTableData = [
            {
                file: 'Raw Data',
                sign: 'raw',
            },
            {
                file: 'Report Data',
                sign: 'overview',
            },
        ];

        const downloadTypeVisible = ref(false);

        const download = () => {
            // if (!payoutRoundYear.value) {
            //     message.error('Payout Round (Year) and BU must be selected!');
            //     return;
            // }
            downloadTypeVisible.value = true;
        };
        const closeDownloadTypeModal = () => {
            downloadTypeVisible.value = false;
        };

        //下载
        const handlerDownload = (selectedData: any) => {
            dogetParameter('');
            let downloadType;
            if (selectedData.length > 1) {
                downloadType = 'zip';
            } else {
                downloadType = selectedData[0].sign;
            }
            parameterDataLst.value.flag = downloadType;
            const paramsConfig = {
                url: `/rvapi/report/vl/download/overall?month=${parameterDataLst.value.month}&bu=${parameterDataLst.value.bu}&buId=${parameterDataLst.value.buId}&xrate=${parameterDataLst.value.xrate}&year=${parameterDataLst.value.year}&flag=${parameterDataLst.value.flag}`,
                method: 'get',
                // params: parameterDataLst.value,
            };
            downloadFile(
                paramsConfig,
                'application/vnd-excel; char-set=UTF-8'
            ).then(() => {
                downloadTypeVisible.value = false;
            });
        };

        return {
            download,
            downloadTypeVisible,
            closeDownloadTypeModal,
            downloadTableData,
            dealerByRegionData,
            dealerBySubRegionData,
            smtByRegionData,
            smtBySubRegionData,
            rowdataData,
            inputPersent,
            endPeriod,
            tabN,
            tabChange,
            handlerDownload,
            dogetAllData,
            dogetParameter,
            buList,
            handlerReset,
            doGetBuList,
            qmOptions,
            parameterList,
            doGetDealerData,
            searchCon,
            tableWidth,
            tableWidth2,
            tableWidth3,
            tableBoxHeight,
            tableHeight,
            tableHeight2,
            sunCol1,
            sunCol2,
            sunCol3,
            sunCol4,
            rawDataCol,
            SHOW_PARENT,
        };
    },
});
