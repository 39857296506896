import { fetchPost, fetchGet, fetchPostPic } from '../index';
import axios from 'axios';
import { TypeClassItem } from '@/views/Approve/SalesRoleApprove/ProgramHome/types';
// 查询Dealer table数据接口
export const getVlList = (
    params = {
        month: [],
        bu: '',
        buId: '',
        xrate: '',
        year: '',
        flag: '',
    }
): Promise<any> => {
    let monthStr = ''
    if(params.month.length>0){
        params.month.forEach((i: any)=>{
            monthStr+=`&month=${i}`
        })
    }
    return fetchGet(
        `/rvapi/report/vl/list?bu=${params.bu}${monthStr}&buId=${params.buId}&xrate=${params.xrate}&year=${params.year}&flag=${params.flag}`,
        params
    );
};
